import React, { useState } from "react";
import {
    Button,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import ViewListIcon from "@material-ui/icons/ViewList";
import SendIcon from "@material-ui/icons/Send";

import modulesList from "../ModulesList.data.js";
import DeployModulesDialog from "./DeployModulesDialog";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
}));

const DeployModulesTable = (props) => {
    const { userProfile } = props;
    const classes = useStyles();

    // Set up state and handler functions for DeployModulesDialog
    const openDeployModulesDialog_initialState = {
        open: false,
        module: null,
    };

    const [openDeployModulesDialog, setOpenDeployModulesDialog] = useState(
        openDeployModulesDialog_initialState
    );

    const handleOpenDeployModulesDialog = (module) => {
        setOpenDeployModulesDialog({
            open: true,
            module: module,
        });
    };

    const handleCloseDeployModulesDialog = () => {
        setOpenDeployModulesDialog(openDeployModulesDialog_initialState);
    };

    return (
        <>
            <TableContainer component={Paper}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Module ID</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {modulesList.map((module, idx) => (
                            <TableRow key={idx}>
                                <TableCell component="th" scope="row">
                                    <b>{module.shortName}</b>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="caption">
                                        {module.description}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        startIcon={<SendIcon />}
                                        color="secondary"
                                        onClick={() => handleOpenDeployModulesDialog(module)}
                                        style={{marginRight: 5}}
                                    >
                                        Deploy New
                                    </Button>
                                    
                                    {/* Show the "View Existing" button only if a userProfile is not provided,
                                        used for the "/deploy-modules" page - where modules can be deployed without an
                                        association to a specific user profile
                                     */}
                                    { !userProfile && (
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            color="primary"
                                            startIcon={<ViewListIcon />}
                                            onClick={() =>
                                                alert("onClick action to be added.")
                                            }
                                            disabled
                                        >
                                            View Existing
                                        </Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            
            {/* DEPLOY MODULES DIALOG */}
            {openDeployModulesDialog.open && (
                <DeployModulesDialog
                    open={openDeployModulesDialog.open}
                    module={openDeployModulesDialog.module}
                    handleCloseDialog={handleCloseDeployModulesDialog}
                    userProfile={userProfile}
                />
            )}
        </>
    );
};

export default DeployModulesTable;
