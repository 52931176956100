import React, { useState } from 'react';
import { TextField, Button, Typography } from '@material-ui/core';
import { login } from '../firebase/FirebaseAuthService';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    

    const LoginButtonClick = (e) => {
        e.preventDefault();
        login(email, password);
    }

    return (
        <div>
            <Typography>Login component says: You're not logged in.</Typography>
            <form>
                <TextField id="outlined-basic" label="Email" variant="outlined" onChange={(e) => setEmail(e.target.value)} />
                <TextField id="outlined-basic" type='password' label="Password" variant="outlined" onChange={(e) => setPassword(e.target.value)}  />
                <Button type='submit' variant="contained" color="primary" onClick={LoginButtonClick}>
                    Login
                </Button>
            </form>
        </div>
    );
};
export default Login;