import React, { useState } from 'react';

// Routing
import { useSearchParams } from 'react-router-dom';

// Components
import BuyerProfileView from './BuyerProfileView/BuyerProfileView.component';

// Display
import { makeStyles, 
    Typography, Grid, TextField, Box, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';



const useStyles = makeStyles(theme => ({
    spaceAround: {
        marginLeft: 10,
        marginRight: 10
    },
}));

    

const CoachPage = (props) => {
    let [URLQueryParams] = useSearchParams();
    const emailUrlParam = URLQueryParams.get("email");

    const { enqueueSnackbar } = useSnackbar();

    const [selectedUserEmail, setSelectedUserEmail] = useState(emailUrlParam);
    const [emailEntry, setEmailEntry] = useState(emailUrlParam ? emailUrlParam : '');
    const classes = useStyles();

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleGo();
        }
    };
    
    const handleGo = () => {
        if (emailEntry.match(/\S+@\S+\.\S+/)) {
            setSelectedUserEmail(emailEntry);
        } else {
            enqueueSnackbar('Please enter a valid email address to proceed.', {variant: 'error', autoHideDuration: 2000});
        }
    }
    
    return (
        <Grid container justifyContent='center'>
            <Grid item xs={12} md={10} lg={8} xl={6}>
                <Typography variant='h4' align='center'><b>Coach Car Buyers</b></Typography>
                <Box m={3.0} />
                <Grid container alignItems='center'>
                    <Typography variant='h6'>Buyer Profile for: </Typography>
                    <Grid item xs className={classes.spaceAround}>
                        <TextField
                            variant='outlined' label="User's Email Address" fullWidth
                            value={emailEntry} onChange={(e) => setEmailEntry(e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e)}
                        />
                    </Grid>
                    <Button variant='contained' color='primary' onClick={handleGo} >GO</Button>
                    
                </Grid>
                <Box m={1.0} />
                {
                    selectedUserEmail && (
                        <>
                            <BuyerProfileView selectedUserEmail={selectedUserEmail} />
                        </>
                    )
                }
            </Grid>
        </Grid>
    )
}

export default CoachPage;