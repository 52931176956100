import React, { useState, useEffect } from 'react';

// Firestore
import { getUserDocuments } from '../../../firebase/FirestoreService';

// Components for view
import { makeStyles, Typography, Box, Grid, IconButton,
    Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { ExpandMore, FindInPage } from '@material-ui/icons';
// import FindInPageIcon from '@material-ui/icons/FindInPage';

import NativeForms from 'native-forms-react';
import UserSummaryTable from './components/UserSummaryTable.component';
import SurveyDetails from './components/surveyDetailsDialog.component';
import CoachingActionsUI from "../CoachingActions/CoachingActionsUI.js";
// import SurveyDataTable from './components/SurveyDataTable.component';


const useStyles = makeStyles(theme => ({
    accordionSummary: {
        backgroundColor: '#ccffff'
    }
}));

const BuyerProfileView = ({ selectedUserEmail }) => {
    const [userProfile, setUserProfile] = useState(null);
    
    // Retrieve the user profile document from database
    useEffect(() => {
        getUserDocuments(selectedUserEmail, setUserProfile);
    }, [selectedUserEmail]);
    
    const classes = useStyles();
    const surveyKeys = {
        keys: ['survey1', 'survey2', 'survey3', 'survey4'],
        labels: [
            '1. Getting to Know You',
            '2. What are you looking for in your next car?',
            '3. How Can We Get In Touch with You?',
            '4. Let’s Set a Goal!'
        ]
    };
    
    // Logic for Form Details Dialog
    const [detailsDialogVisible, setDetailsDialogVisible] = useState('');
    const [formDetailsDataToDisplay, setFormDetailsDataToDisplay] = useState('');
    const handleDialogOpenClick = (objectKey) => {
        setFormDetailsDataToDisplay(objectKey);
        toggleDialogOpen('open');
    }
    const toggleDialogOpen = (action) => {
        if (action === 'open') {
            setDetailsDialogVisible(true);
        } else if (action === 'close') {
            setDetailsDialogVisible(false);
        }
    };

    // End - Logic for Form Details Dialog


    console.log('User profile document: ', userProfile);

    return (
        <div className={classes.main}>
            {
                userProfile ?
                (
                    <div>
                        <Accordion defaultExpanded>
                            <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMore />}>
                                <Typography variant='h5'>Summary Info</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <UserSummaryTable userProfile={userProfile} />
                            </AccordionDetails>
                        </Accordion>
                        <Box m={3.0} />
                        
                        {/* DISPLAY COACHING SIGN-UP SURVEYS, Presented as 1 survey per Accordian */}
                        {
                            surveyKeys.keys.map((objectKey, idx) => {
                                if ( userProfile.carBuyerInfo.coachingSurveyData[objectKey] ) {
                                    return (
                                        <div key={idx}>
                                            <Accordion >
                                                <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMore />}>
                                                    <Grid container justifyContent='space-between' alignItems='center'>
                                                        <Grid item xs={11}>
                                                            <Typography variant='h5'>{surveyKeys.labels[idx]}</Typography>
                                                        </Grid>
                                                            <IconButton onClick={() => handleDialogOpenClick(objectKey)}>
                                                                <FindInPage />
                                                            </IconButton>
                                                        <Grid item xs={1}>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {
                                                        // <SurveyDataTable surveyData={userProfile.carBuyerInfo.coachingSurveyData[objectKey]} />
                                                    }
                                                    <NativeForms formJSON={userProfile.carBuyerInfo.coachingSurveyData[objectKey].form} isPreview />
                                                </AccordionDetails>
                                            </Accordion>
                                            <Box m={3.0} />
                                        </div>
                                    )
                                } else {
                                    return null;
                                }
                            })
                        }
                        
                        {/* COACHING ACTIONS MENU - Appears at bottom-right of page */}
                            <CoachingActionsUI userProfile={userProfile} />
                        {/* SURVEY DETAILS DIALOG - Shows JSON details from NativeForms */}
                        {
                            detailsDialogVisible ?
                                <SurveyDetails 
                                    detailsDialogVisible={detailsDialogVisible}
                                    toggleDialogOpen={toggleDialogOpen}
                                    formDetailsDataToDisplay={formDetailsDataToDisplay}
                                    surveyKeys={surveyKeys}
                                    userProfile={userProfile}
                                />
                            :
                                null
                        }
                        </div>
                    )
                :
                    (
                        <Typography variant='body1'>No data to display.</Typography>
                    )
            }
        
            
        </div>
    );
};

export default BuyerProfileView;