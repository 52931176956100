import React from 'react';

import { makeStyles, withStyles,
    ButtonBase,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';


const useStyles = makeStyles(theme => ({
    main: {

    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontWeight: 600,
        backgroundColor: 'darkgray',
        whiteSpace: 'nowrap'
    }
}))(TableCell);

const UserSummaryTable = ({ userProfile }) => {
    const classes = useStyles();
    
    const openLink = (url) => {
        window.open(url)
    };

    let signUpDate;
    if (userProfile) {
        const d = new Date(userProfile.carBuyerInfo.signup_date);
        signUpDate = d.toDateString();
    }

    return (
        <TableContainer className={classes.main}>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Field Name</StyledTableCell>
                        <StyledTableCell>Field Value</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <StyledTableCell>First Name</StyledTableCell>
                        <TableCell>{userProfile.identity.firstName}</TableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>Last Name</StyledTableCell>
                        <TableCell>{userProfile.identity.lastName}</TableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>Email Address</StyledTableCell>
                        <TableCell>{userProfile.identity.email}</TableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>Signup Date</StyledTableCell>
                        <TableCell>{signUpDate}</TableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>Purchase Timeline</StyledTableCell>
                        <TableCell>{userProfile.carBuyerInfo.purchase_timeline}</TableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>Phone Number</StyledTableCell>
                        <TableCell>{userProfile.identity.phone}</TableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>Organization</StyledTableCell>
                        <TableCell>{userProfile.identity.organization}</TableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>Referral Source</StyledTableCell>
                        <TableCell>{userProfile.identity.referral_source}</TableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>User ID</StyledTableCell>
                        <TableCell>{userProfile.identity.firebaseUID}</TableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>Hubspot Contact</StyledTableCell>
                        <TableCell>
                            <ButtonBase onClick={() => openLink(`https://app.hubspot.com/contacts/20171276/contact/${userProfile.identity.hubspotContactId}/`)}>
                                <LaunchIcon />
                            </ButtonBase>
                                {userProfile.identity.hubspotContactId}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>Hubspot Deal</StyledTableCell>
                        <TableCell>
                            <ButtonBase onClick={() => openLink(`https://app.hubspot.com/contacts/20171276/deal/${userProfile.identity.hubspotDealId}/`)}>
                                <LaunchIcon />
                            </ButtonBase>
                                {userProfile.identity.hubspotDealId}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UserSummaryTable;