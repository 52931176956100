import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import ReactJson from "react-json-view";

import { createNewDocument, getCurrentTimestamp } from "../../../firebase/FirestoreService.js";
import ConfirmDeployDialog from "./ConfirmDeployDialog.js";


const DeployModulesDialog = (props) => {
    const { open, module, handleCloseDialog, userProfile } = props;
    
    const [openConfirmDialog, toggleConfirmDialog] = useState(false);
    const [deployURL, setDeployURL] = useState();

    const [instanceDataJSON, setInstanceDataJSON] = useState();

    
    const handleDeployButtonClick = async () => {
        const documentID = await createNewDocument(
            "ModuleInstanceData",
            instanceDataJSON
        );
        setDeployURL(`https://coach.electrifyze.com/module/${module.moduleID}?iID=${documentID}`);
        toggleConfirmDialog(true);

    };

    // Function called when closing the deploy confirmation dialog
    const handleCloseConfirmDialog = () => {
        toggleConfirmDialog(false);
        handleCloseDialog();
    }

    // Load up the JSON template for the correct module
    useEffect(() => {
        const moduleInstanceInputs = {
            createdAt: getCurrentTimestamp(),
            userProfile: userProfile
        }
        
        import(`../ModuleData/${module.instanceDataTemplate}`).then(
            (importedModuleData) =>
                setInstanceDataJSON(importedModuleData.default(moduleInstanceInputs))
        );
    }, [module]);

    return (
        <div>
            
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Deploy "{module.shortName}" Module to 
                    {userProfile ? ` "${userProfile.identity.email}"` : " a User"}
                </DialogTitle>
                <DialogContent>
                    <ReactJson
                        src={instanceDataJSON}
                        onEdit={(event) =>
                            setInstanceDataJSON(event.updated_src)
                        }
                        enableClipboard={true}
                        quotesOnKeys={false}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeployButtonClick} color="primary">
                        Deploy Module
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Confirmation Dialog with Deploy URL */}
            {openConfirmDialog && (
                <ConfirmDeployDialog
                    module={module}
                    openConfirmDialog={openConfirmDialog}
                    handleCloseConfirmDialog={handleCloseConfirmDialog}
                    deployURL={deployURL}
                />
            )}
        </div>
    );
};

export default DeployModulesDialog;
