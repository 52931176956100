import React from 'react';

import { makeStyles, withStyles,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    main: {

    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontWeight: 600,
        backgroundColor: 'darkgray',
        whiteSpace: 'nowrap'
    }
}))(TableCell);

const SurveyDataTable = ({ dataToDisplay }) => {
    const classes = useStyles();
    
    return (
        <TableContainer className={classes.main}>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Field</StyledTableCell>
                        <StyledTableCell>Value</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Object.keys(dataToDisplay).map((key, idx) => {
                            if (typeof dataToDisplay[key] === 'object') {
                                return (
                                    <TableRow key={idx}>
                                        <TableCell>{key}</TableCell>
                                        <TableCell>Object</TableCell>
                                    </TableRow>
                                )
                            } else {
                                return (
                                    <TableRow key={idx}>
                                        <TableCell>{key}</TableCell>
                                        <TableCell>{dataToDisplay[key]}</TableCell>
                                    </TableRow>
                                )
                            }
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SurveyDataTable;