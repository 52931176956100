import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    position: "absolute",
    zIndex: theme.zIndex.drawer - 1,
    color: '#fff',
  },
}));

const DialogLoadingBackdrop = (props) => {
  // Call "LoadingBackdrop" with props loadingState=true to show, or loadingState=false to hide
  const { loadingState } = props;
  const classes = useStyles();

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loadingState}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DialogLoadingBackdrop;