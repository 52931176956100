import firebaseApp from "./FirebaseConfig";

import {
    doc,
    getDocs,
    collection,
    query,
    where,
    getDoc,
    addDoc,
    Timestamp
    // serverTimestamp,
} from "firebase/firestore";

const firestore = firebaseApp.firestore;

export const readDocument = async (collectionName, docName) => {
    const collectionRef = collection(firestore, collectionName);
    const docRef = doc(collectionRef, docName);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data();
    } else {
        console.error(
            `Document ${docName} does not exist in collection ${collectionName} `
        );
    }
};

// Retrieve a user's full profile document - for Admin panel
export const getUserDocuments = async (email, setUserProfile) => {
    const q = query(
        collection(firestore, "user_profiles"),
        where("identity.email", "==", email.toLowerCase())
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length === 1) {
        return querySnapshot.forEach(async (doc) => {
            const docData = doc.data();
            // Set state in the calling component
            setUserProfile(docData);
        });
    } else if (querySnapshot.docs.length > 1) {
        console.error("Multiple user documents exist for : ", email);
        return null;
    } else {
        console.error("No user document for : ", email);
        return null;
    }
};

// Create a new Firestore Document with a generated document ID
export const createNewDocument = async (collectionName, docData) => {
    const collectionRef = collection(firestore, collectionName);
    try {
        const docRef = await addDoc(collectionRef, docData);
        // console.log("Document written with ID: ", docRef.id)
        return docRef.id;
    } catch (error) {
        console.error(error.message);
    }
};

export const getCurrentTimestamp = () => {
    return Timestamp.now().toDate();
}