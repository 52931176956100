import React, { useState } from 'react';

// Routing
import { Routes, Route } from 'react-router-dom';

// Services
import { subscribeToAuthChanges } from './firebase/FirebaseAuthService';

// Display
import MiniDrawer from './components/MiniDrawer.component';

// Pages
import Login from './components/Login.component';
import Homepage from './pages/Homepage/Homepage.component';
import CoachPage from './pages/CoachBuyers/CoachPage.component';
import ManageTeamPage from './pages/ManageTeam/ManageTeam.component';
import DialogLoadingBackdrop from './components/DialogLoadingBackdrop.component';
import GenerateQRCodes from './pages/GenQRCodes/GenQRCodes.component';
import DeployModulesPage from './pages/DeployModules/DeployModulesPage';



function App() {
  const [user, setUser] = useState('loading');
  console.log('Auth subscription returned: ', user);
  subscribeToAuthChanges(setUser);
  if (user) {
    // console.log('Custom claims : ', user.customClaims);
  }

  
  
  return (
    <div className="App">
      {
        user === 'loading' ?
          <DialogLoadingBackdrop loadingState />
          :
        user ?
          <MiniDrawer>
            <Routes>
                <Route exact path='/' element={<Homepage />} />
                
                { user.customClaims.coach ?
                    <Route exact path='/coach' element={<CoachPage />} /> : null
                }
                { user.customClaims.coach ?
                    <Route exact path='/deploy-modules' element={<DeployModulesPage />} /> : null
                }
                { user.customClaims.admin ? 
                    <Route exact path='/manage-team' element={<ManageTeamPage />} /> :  null
                }
                { user.customClaims.admin ? 
                    <Route exact path='/gen-qr-codes' element={<GenerateQRCodes />} /> :  null
                }
            </Routes>
          </MiniDrawer>
        :
          <Login />
      }
    </div>
  );
}

export default App;
