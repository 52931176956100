// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBk4qIav0yS9P819H3BghJORQgTEaUEdwQ",
  authDomain: "electrifyze-ev-buying.firebaseapp.com",
  projectId: "electrifyze-ev-buying",
  storageBucket: "electrifyze-ev-buying.appspot.com",
  messagingSenderId: "862888001011",
  appId: "1:862888001011:web:8848df1c913c3e5336a686",
  measurementId: "G-8E9PQ5R9GR"
};

// NOTE: In other Electrifyze Apps that are intended to be deployed as NPM packages, we set up conditional logic for initializing the Firebase App.
// Conditional logic typically detects whether other Firebase apps have already been initialized and then uses "getApp" if an existing app is initialized,
// OR uses initializeApp if no existing app (tied to the correct Firebase project) has been initialized.

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);

const firebaseApp = {
    auth,
    firestore,
    functions,
    analytics
};

export default firebaseApp;
