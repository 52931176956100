import React, { useState } from 'react';

import { makeStyles, Typography, Grid, Box, TextField } from '@material-ui/core';
import { QRCodeSVG } from 'qrcode.react';

const useStyles = makeStyles(theme => ({
    spaceAround: {
        marginLeft: 10,
        marginRight: 10
    },
}));

const GenerateQRCodes = () => {
    const [urlToGenerate, setUrlToGenerate] = useState('');
    const classes = useStyles();

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            // handleGenerate();
        }
    };
    
    // const handleGenerate = () => {
    //     if (emailEntry.match(/\S+@\S+\.\S+/)) {
    //         setSelectedUserEmail(emailEntry);
    //     } else {
    //         enqueueSnackbar('Please enter a valid email address to proceed.', {variant: 'error', autoHideDuration: 2000});
    //     }
    // }

    return (
        <Grid container justifyContent='center'>
            <Grid item xs={12} md={10} lg={8} xl={6}>
                <Typography variant='h4' align='center'>Generate QR Codes</Typography>
                <Box m={3.0} />
                <Grid container alignItems='center'>
                    <Typography variant='h5'>URL to Generate QR Code: </Typography>
                    <Grid item xs className={classes.spaceAround}>
                        <TextField
                            variant='outlined' label="URL to Generate QR" fullWidth
                            value={urlToGenerate} onChange={(e) => setUrlToGenerate(e.target.value)}
                            // onKeyDown={(e) => handleKeyDown(e)}
                        />
                        </Grid>
                    {
                    // <Button variant='contained' color='primary' onClick={handleGo} >GO</Button>
                    }
                </Grid>
                <Box m={1.0} />
                {
                    URL ? 
                        <QRCodeSVG 
                            value={urlToGenerate}
                            level='M' 
                        />
                    :
                        null
                }
                

            </Grid>
        </Grid>
    )
};

export default GenerateQRCodes;