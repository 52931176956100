import firebaseApp from './FirebaseConfig.js';
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';

const auth = firebaseApp.auth;

export const login = async (email, password) => {
    // Sign in
    try {
        const cred = await signInWithEmailAndPassword(auth, email, password);
        return cred;
    } catch (error) {
        console.error(error.message);
    }
};

export const logout = async () => {
    // Sign out
    try {
        signOut(auth);
    } catch (error) {
        console.error(error.message);
    }
};

export const subscribeToAuthChanges = (handleAuthChange) => {
    onAuthStateChanged(auth, async (user) => {
        
        if (user) {
            // Grab the relevant custom claims and attach to user object
            const idTokenResult = await user.getIdTokenResult();
            // console.log(idTokenResult);
            user.customClaims = {
                admin: idTokenResult.claims.admin,
                coachManager: idTokenResult.claims.coachManager,
                coach: idTokenResult.claims.coach
            };
        }
        
        // Note: This callback function call needs to come at the end, so any operations on the user object get passed into state of the calling component
        if (handleAuthChange) {
            // Run the callback function, if one was provided.
            // Usually the callyback function is a call to setState, specifically setUser
            handleAuthChange(user);
        }
    });
};
