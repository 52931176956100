import React from "react";
import { Box, Typography } from "@material-ui/core";


// Components
import DeployModulesTable from "./components/DeployModulesTable";

const DeployModulesPage = () => {
    return (
        <div>
            <Typography variant="h4" align="center">
                <b>Deploy Coaching Modules</b>
            </Typography>
            <Box m={3.0} />
            <Typography>The following modules can be deployed:</Typography>
            <Box m={3.0} />
            <DeployModulesTable />
        </div>
    );
};

export default DeployModulesPage;
