import React from 'react';

import { makeStyles, Dialog, DialogContent,
    Typography, Grid, TextField, Box, Button } from '@material-ui/core';
import CustomDialogTitle from '../../../../components/CustomDialogTitle.component.js';

import SurveyDataTable from './SurveyDataTable.component.js';
import ReactJson from 'react-json-view'

const useStyles = makeStyles(theme => ({
    
}));

const SurveyDetailsDialog = (props) => {
    const { detailsDialogVisible,
        toggleDialogOpen,
        formDetailsDataToDisplay,
        surveyKeys,
        userProfile } = props;

    
    const classes = useStyles();

    const formDetailsToView = userProfile.carBuyerInfo.coachingSurveyData[formDetailsDataToDisplay].form;
    console.log('formDetailsToView: ', formDetailsToView)

    return (
        <Dialog 
            maxWidth='md'
            fullWidth
            aria-labelledby="customized-dialog-title"
            open={detailsDialogVisible}
        >
            <CustomDialogTitle id="customized-dialog-title" onClose={() => toggleDialogOpen('close')}>
                Survey Details
            </CustomDialogTitle>
            <DialogContent dividers>
                {
                    // <SurveyDataTable
                    //     dataToDisplay={formDetailsToView}
                    // />
                }
                <ReactJson src={formDetailsToView} />
            </DialogContent>
        
        </Dialog>
    );
};

export default SurveyDetailsDialog;