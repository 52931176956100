import React, { useState } from 'react';

// Material UI Imports
import { makeStyles,
    Button, Box,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Typography
  } from '@material-ui/core';

const useStyles = makeStyles(theme => ({

}));

const AddTeamMemberDialog = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add Team Member
        </Button>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add New Team Member</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant='body1'>
                        To add a new team member, ask Sam to add the UID to the array:
                    </Typography>
                    <Box m={2.0} />   
                    <Typography variant='body2' align='center'>
                        | admin_panel / customClaimsUserList[customClaimsUserList]
                    </Typography>
                    <Box m={2.0} />   
                    <Typography variant='caption text'>
                        This value can be set from the Firestore web console.
                        Once added, the team member will appear in the Table.
                    </Typography>
                    

                </DialogContentText>

            </DialogContent>
            <DialogActions>
                
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
      </Dialog>
        
        </div>
    );
};

export default AddTeamMemberDialog;