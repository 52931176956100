import React from 'react';
import ReactDOM from 'react-dom';
import './static/index.css';
import App from './App';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import Zoom from '@material-ui/core/Zoom';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <SnackbarProvider 
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        TransitionComponent={Zoom}
        >
        { // Note: "SnackbarProvider" must be a child of Material-UI ThemeProvider if we add that later 
        }
          <App />
        </SnackbarProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
