import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    shareLink: {
        /* border: '1px solid black',
        padding: 5,*/
        width: 450,
        color: "black",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        /* borderRadius: 5,
        maxHeight: 24 */
    },
}));

const ConfirmDeployDialog = (props) => {
    const { module, openConfirmDialog, handleCloseConfirmDialog, deployURL } = props;
    const [copied, setCopied] = useState(false);

    const classes = useStyles();

    const handleCopyClick = () => {
        if (/*@cc_on!@*/ false || !!document.documentMode) {
            //Internet explorer has unique method
            window.clipboardData.setData("Text", deployURL);
        } else {
            try {
                navigator.clipboard.writeText(deployURL);
                // Note: I think this code causes a crash when run in Safari while served from a non-https source (e.g. localhost)
                // I've put it into a try/catch to prevent a crash during local testing. But clipboard copying won't work in those cases.
            } catch (error) {
                console.error("Error copying to clipboard;", error.message);
            }
        }
        setCopied(true);
    };

    return (
        <Dialog
            open={openConfirmDialog}
            onClose={handleCloseConfirmDialog}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                Successfully deployed "{module.shortName}" module
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        Deploy URL:
                    </Grid>
                    <Grid item xs>
                        <TextField
                            variant="outlined"
                            margin="dense"
                            className={classes.shareLink}
                            value={deployURL}
                        />
                    </Grid>

                    <Grid item>
                        <Button variant="outlined" onClick={handleCopyClick}>
                            {copied ? "Copied!" : "Copy"}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseConfirmDialog} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDeployDialog;
