// This file contains a list of modules that coaches can deploy to users.
// Key specifications for each module are provided as an object in the array.

// See the below GDoc for a developer's guide on how to deploy new modules
// https://docs.google.com/document/d/1Cn4dVg2Aao42pI-TytTOQ84CJktKFHB0Wo2cum30RI0/edit?usp=sharing

const modulesList = [
    {
        moduleID: "CarPurchaseCostEstimator",
        shortName: "Car Purchase Cost Estimator",
        description:
            "Walks users through understanding the full purchase costs of buying a car (including MSRP, markups, discounts, taxes, fees, incentives), then provides estimated numbers for a specified car.",
        instanceDataTemplate: "CarPurchaseCostEstimator.js",
    },
    {
        moduleID: "incentivesDemo",
        shortName: "Incentives Demo",
        description:
            "A demo learning module with a few slides about incentives.",
    },
];

export default modulesList;