import React, { useState, useEffect } from 'react';

// Material UI Imports
import { makeStyles,
  Button, Checkbox,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  FormLabel, FormControl, FormGroup, FormControlLabel, FormHelperText
} from '@material-ui/core';

// Firebase & Back-End Imports
import { setUserCustomClaims } from '../../../firebase/FirebaseFunctionsService';


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    formControl: {
      margin: theme.spacing(3),
    },
  }));

const SetClaimsDialog = (props) => {
  const classes = useStyles();
  const { userToSet } = props
  const [open, setOpen] = useState(false);
  const [claims, setClaims] = useState({
    admin: false,
    coachManager: false,
    coach: false
  });

  // console.log('Initial claims for "', userToSet.email, ":", userToSet.customClaims)
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setClaimsClick = async () => {
    try {
        const response = await setUserCustomClaims({uidToUpdate: userToSet.uid, claimsToSet: claims})
        // console.log('Response from claims update: ', response);
        handleClose();

    } catch (error) {
        alert(error.message);
    }
  }
  
  const handleChange = (event) => {
    setClaims({ ...claims, [event.target.name]: event.target.checked });
  };

  return (
    <div>
        <Button variant="outlined" color="secondary" onClick={handleClickOpen}>
            Set Claims
        </Button>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Set Custom Claims</DialogTitle>
            <DialogContent>
                <DialogContentText>
                  Set custom claims for <b>{userToSet.email}</b> :
                </DialogContentText>
            

                <div className={classes.root}>
                  <FormControl component="fieldset" className={classes.formControl}>
                      <FormLabel component="legend">Claims to set</FormLabel>
                      <FormGroup>
                      <FormControlLabel
                          control={<Checkbox checked={claims.admin} onChange={handleChange} name="admin" />}
                          label="admin"
                      />
                      <FormControlLabel
                          control={<Checkbox checked={claims.coachManager} onChange={handleChange} name="coachManager" />}
                          label="coachManager"
                      />
                      <FormControlLabel
                          control={<Checkbox checked={claims.coach} onChange={handleChange} name="coach" />}
                          label="coach"
                      />
                      </FormGroup>
                      <FormHelperText>Be careful :)</FormHelperText>
                  </FormControl>
                </div>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={setClaimsClick} color="primary">
                    Set Claims
                </Button>
            </DialogActions>
      </Dialog>
    </div>
  );
}

export default SetClaimsDialog;