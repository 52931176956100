import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import DeployModulesTable from "../../DeployModules/components/DeployModulesTable";

const DeployModulesToUserDialog = (props) => {
    const { open, handleCloseDialog, userProfile } = props;

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                maxWidth="lg"
                fullWidth={true}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Deploy a New Instance of a Module to "{userProfile.identity.email}"
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1" sx={{marginBottom: 1, paddingLeft: 2}}>
                        Choose a module to deploy, then click "Deploy New".
                    </Typography>
                    <DeployModulesTable 
                        userProfile={userProfile}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DeployModulesToUserDialog;
