import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SetClaimsDialog from './SetClaimsDialog.component';

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650,
      },
}));

const listCustomClaimsAsString = (claimsObject) => {
    let claimsString;
    if (claimsObject) {
        const claimsArray = Object.keys(claimsObject).map((key, idx) => {
            if (claimsObject[key]) {
                return key;
            }
        });
        const claimsArrayFiltered = claimsArray.filter((element) => {
            return element !== undefined;
         });
        claimsString = claimsArrayFiltered.join(", ");
    } else {
        claimsString = '';
    }
    return claimsString;
}

const UsersCustomClaimsTable = ({customClaimsUserList}) => {
    const classes = useStyles();
    
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Display Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Custom Claims</TableCell>
                        <TableCell>Set Parameters</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {customClaimsUserList.map((user, idx) => (
                        <TableRow key={idx}>
                            <TableCell component='th' scope='row'>{user.displayName}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{listCustomClaimsAsString(user.customClaims)}</TableCell>
                            <TableCell>
                                <SetClaimsDialog userToSet={user} />
                            </TableCell>
                            
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        
        </TableContainer>
    );
}

export default UsersCustomClaimsTable;