import React, { useState, useEffect } from "react";

// Firebase imports
import { readDocument } from "../../firebase/FirestoreService";
import { listUsersWithCustomClaims } from "../../firebase/FirebaseFunctionsService";

// Material UI
import {
    makeStyles,
    Typography,
    Box,
    CircularProgress,
    Grid,
} from "@material-ui/core";

// Components
import UsersCustomClaimsTable from "./components/UsersCustomClaimsTable.component";
import AddTeamMemberDialog from "./components/AddTeamDialog.component";

const useStyles = makeStyles((theme) => ({}));

const getCustomClaimsUserList = async (callbackFunction) => {
    const docData = await readDocument("admin_panel", "customClaimsUserList");
    const customUserClaimsList = docData.customClaimsUserList;
    console.log("CustomUserClaimsList: ", customUserClaimsList);

    // TO DO: Place the below line into a TRY/CATCH with Error Handling
    const customClaimsUserList = await listUsersWithCustomClaims({
        uidsToRetrieve: customUserClaimsList,
    });
    callbackFunction(customClaimsUserList.data.users);
};

const ManageTeamPage = (props) => {
    const classes = useStyles();

    // Grab info about which users
    const [customClaimsUserList, setCustomClaimsUserList] = useState([]);
    useEffect(() => {
        getCustomClaimsUserList(setCustomClaimsUserList);
    }, []);

    // console.log('User list: ', customClaimsUserList);

    return (
        <div>
            <Typography variant="h4" align="center">
                <b>Manage Team</b>
            </Typography>
            <Box m={3.0} />

            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography>
                        <b>The following users have custom claims set:</b>
                    </Typography>
                </Grid>
                <Grid item>
                    <AddTeamMemberDialog />
                </Grid>
            </Grid>

            <Box m={3.0} />
            {customClaimsUserList.length > 0 ? (
                <UsersCustomClaimsTable
                    customClaimsUserList={customClaimsUserList}
                />
            ) : (
                <CircularProgress />
            )}
        </div>
    );
};

export default ManageTeamPage;
