import React, { useEffect, useState } from "react";

import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ViewListIcon from "@mui/icons-material/ViewList";
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import DeployModulesToUserDialog from "./DeployModulesToUserDialog";


const CoachingActionsUI = (props) => {
    const { userProfile } = props;
    const [openDeployModulesToUserDialog, toggleDeployModulesToUserDialog] = useState(false);

    return (
        <>
            <SpeedDial
                ariaLabel="SpeedDial basic example"
                sx={{ position: "fixed", bottom: 16, right: 16 }}
                icon={<SpeedDialIcon icon={<CastForEducationIcon />} openIcon={<CancelPresentationIcon />} />}
            >

                <SpeedDialAction 
                    icon={<SendIcon />}
                    tooltipTitle="Deploy a New Module"
                    onClick={() => toggleDeployModulesToUserDialog(true)}
                />

                
                <SpeedDialAction 
                    icon={<ViewListIcon />}
                    tooltipTitle="View Existing Deployed Modules"
                    onClick={() => alert("Clicked 'View Existing Deployed Modules'")}
                />


            </SpeedDial>
            
            {/* DIALOG WHERE ACTIONS ARE SHOWN */}
            { openDeployModulesToUserDialog && (
                <DeployModulesToUserDialog 
                    open={openDeployModulesToUserDialog}
                    handleCloseDialog={() => toggleDeployModulesToUserDialog(!openDeployModulesToUserDialog)}
                    userProfile={userProfile}
                />
            )}
        </>
    );
};

export default CoachingActionsUI;
