import firebaseApp from './FirebaseConfig.js';
import { httpsCallable } from 'firebase/functions';

const functions = firebaseApp.functions;

// Returns a list of Firebase Auth users who have custom claims set
// TODO: Add _CloudFunction to the end of this function name, for clarity throughout the code.
export const listUsersWithCustomClaims = httpsCallable(functions, 'admin-listUsersWithCustomClaims');

// Sets the custom claims on the specified user
// TODO: Add _CloudFunction to the end of this function name, for clarity throughout the code.
export const setUserCustomClaims = httpsCallable(functions, 'admin-setUserCustomClaims');