import React from 'react';

import { withStyles, IconButton, Typography } from '@material-ui/core';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
        [theme.breakpoints.down('xs')]: {
            right: theme.spacing(0),
            top: theme.spacing(0.5),
        }
    },
    dialogTitle: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.9rem'
        }
    },
    
  });

const CustomDialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6" className={classes.dialogTitle}>{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  export default CustomDialogTitle;